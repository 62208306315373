<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <modal-details-remark
          :remark-data.sync="remarkData"
          @refetch-data="refetchData"
          @hide-remarks="hideRemarks"
        />
        <!-- Table Top -->

        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">CLIENT</h3>
          <div class="form-col-select ml-2">
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block" />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary w-100">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Find Client" />
          </div>
          <b-button variant="primary" class="px-3 mobile-w100" :to="{ name: 'clients-create' }"
            v-if="ability.can('create', 'client_account')">
            <span class="text-nowrap"><feather-icon size="14" icon="PlusIcon" /> Client Account</span>
          </b-button>
        </div>

        <div class="d-flex mt-2 mt-sm-1 flex-wrap flex-sm-nowrap justify-content-between">
          <div class="d-flex mt-2 mt-sm-1 flex-wrap flex-sm-nowrap">
            <b-button class="mr-2 mt-1 mt-sm-0" variant="primary" @click="statusFilter = null">
              <feather-icon icon="AlignJustifyIcon" class="mr-50" />
              All
            </b-button>
            <b-button class="mr-2 mt-1 mt-sm-0" variant="primary" @click="statusFilter = '1'">
              <feather-icon icon="BookOpenIcon" class="mr-50" />
              Active
            </b-button>
            <b-button class="mr-2 mt-1 mt-sm-0" variant="primary" @click="statusFilter = '2'">
              <feather-icon icon="LoaderIcon" class="mr-50" />
              Inactive
            </b-button>
            <b-button class="mr-2 mt-1 mt-sm-0" variant="primary" @click="statusFilter = '3'">
              <feather-icon icon="CheckIcon" class="mr-50" />
              Suspended
            </b-button>
            <b-button class="mt-1 mt-sm-0" variant="primary" @click="statusFilter = '4'">
              <feather-icon icon="XIcon" class="mr-50" />
              Close
            </b-button>
          </div>
          <div class="d-flex mt-2 mt-sm-1 flex-wrap flex-sm-nowrap">
            <b-overlay :show="show" rounded="sm" spinner-variant="primary">
              <div class="d-flex mt-2 mt-sm-1 flex-wrap flex-sm-nowrap">
                <b-button v-on:click="onBtnExportSimple()" class="mr-1 btn-primary-green mobile-w100">
                  <feather-icon icon="FileTextIcon" class="mr-50" size="16" />
                  Export Simple
                </b-button>
                <b-button v-on:click="onBtnExport()" class="btn-primary-green mobile-w100">
                  <feather-icon icon="FileTextIcon" class="mr-50" size="16" />
                  Export
                </b-button>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>

      <b-modal id="remarks-modal" centered size="lg" hide-footer title="Client Remarks">
        <!-- <app-timeline class="m-2">
          <template v-if="remarks.length > 0">
            <app-timeline-item v-for="(remark, index) in remarks" :key="`client_remark_${remark.id}_${index}`"
              :title="`Remarks by: ${remark.user.name}`" :subtitle="`${remark.remarks}`" :time="`${remark.date}`"
              variant="success" />
          </template>
        </app-timeline> -->
        <b-overlay :show="show" rounded="sm">
          <app-timeline class="m-2">
            <template v-if="remarks.length > 0">
              <app-timeline-item v-for="(remark, index) in remarks" :key="`client_remark_${remark.id}_${index}`">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>Remarks by: {{ remark.user.name }}</h6>
                  <small class="text-muted">{{ remark.date }}</small>
                </div>
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <div v-if="!editRemarkBoolean">
                    <p>{{ remark.remarks }}</p>
                  </div>
                  <div v-else>
                    <b-form-input v-model="remark.remarks" id="remarks" style="width: 300px;" />
                  </div>
                </div>
              </app-timeline-item>
            </template>
          </app-timeline>
        </b-overlay>
      </b-modal>

      <b-modal id="add-remarks" centered size="lg" hide-footer title="Add Client Remarks">
        <b-row class="m-3">
          <b-col cols="12">
            <b-form-group label="Remarks" :label-for="`remarks`" label-cols-md="3" class="required">
              <b-form-input :id="`remarks`" v-model="newRemark.remarks" placeholder="Enter Remarks" />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="modal-bottom">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="addRemarksHide" variant="outline-danger"
            class="mr-1">
            Cancel
          </b-button>
          <!-- @click="submitModal" -->
          <b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1"
            @click="addNewRemarks">
            Submit
          </b-button>
        </div>
      </b-modal>

      <b-table ref="refClientListTable" class="position-relative" :items="fetchClients" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc">
        <template #cell(account_type)="data">
          <div v-for="(type, index) in data.item.account_types" :key="`account_type_${index}`">
            {{ type.name }}
          </div>
        </template>

        <template #cell(custodian_bank)="data">
          <div v-for="(bank, index) in data.item.banks" :key="`bank_${index}`">
            {{ bank.bank && bank.bank.name }}
          </div>
        </template>

        <template #cell(account_status)="data">
          <span :class="{
              'text-succss': data.item.account_status.active_bit,
              'text-danger': !data.item.account_status.active_bit,
            }">
            {{ data.item.account_status.status_name }}</span>
        </template>

        <template #cell(account_number)="data">
          <div v-if="data.item.is_approved == 0">
            <span class="text-warning">{{ data.item.account_number }}</span>
          </div>
          <div v-else>{{ data.item.account_number }}</div>
        </template>

        <!-- <template #cell(remarks)="data">
          <div v-if="data.item.client_remark.length > 0" class="cursor">
            <feather-icon icon="AlertCircleIcon" @click="openModel(data.item.client_remark)" />
          </div>
        </template> -->
        <template #cell(remarks)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            v-if="data.item.client_remark.length > 0"
            @click="openModel(data.item.client_remark)"
          >
            <feather-icon icon="AlertCircleIcon" />
          </b-button>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown class="action-trigger-btn" :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="EyeIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item v-if="ability.can('view', 'client_account')"
              :to="{ name: 'clients-detail', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="ability.can('update', 'client_account')"
              :to="{ name: 'clients-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="ability.can('update', 'client_account')" @click="openModalDetailsRemark(data.item)">
              <feather-icon icon="PlusIcon" />
              <span class="align-middle ml-50">Manage Remarks</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item v-if="data.item.is_company && ability.can('update', 'client_account')" >
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">Select Birthday Email</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalClients" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBModal,
  BFormGroup,
  BForm,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useClientsList from "./useClientsList";
import clientStoreModule from "../clientStoreModule";
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import ModalDetailsRemark from "../modal/ModalDetailsRemark.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AppTimeline,
    AppTimelineItem,
    VBModal,
    BFormGroup,
    BForm,
    BOverlay,
    ModalDetailsRemark,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  methods: {
    triggerResetPasswordModal(item) {
      console.log("triggerResetPasswordModal", item);
      this.clientInfo.id = item.id;
      this.clientInfo.name = item.name;
      this.clientInfo.password = "";
      this.clientInfo.password_confirmation = "";
      this.$bvModal.show("modal-reset-client-password");
    },
  },
  data() {
    return {
      clientInfo: { id: 0, password: "", password_confirmation: "" },
      remarks: [],
      newRemark: {
        client_id: null,
        remarks: null,
      },
      show: false,
      editRemarkBoolean: false,
      remarkData: {},
    };
  },
  methods: {
    addRemarksShow(client_id) {
      this.remarkData.client_id = client_id;
      this.$bvModal.show("add-remarks");
    },
    addRemarksHide() {
      this.$bvModal.hide("add-remarks");
    },
    deleteRemark(id) {
      this.show = true;
      store
        .dispatch("app-client/deleteRemark", {id:id})
        .then((response) => {
          this.show = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
          this.$bvModal.hide("remarks-modal");
        })
        .catch((error) => {
          this.show = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    confirmEdit(remark) {
      this.show = true;
      this.editRemarkBoolean = false;
      store
        .dispatch("app-client/addNewRemark", remark)
        .then((response) => {
          this.show = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
          this.editRemarkBoolean = false;
        })
        .catch((error) => {
          this.show = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    editRemark() {
      this.editRemarkBoolean = true;
    },
    addNewRemarks() {
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-client/addNewRemark", this.newRemark)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
              this.addRemarksHide();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    openModalDetailsRemark(item) {
      this.remarkData = { ...item };
      this.$bvModal.show("modal-details-remark");
    },
    hideRemarks() {
      this.$bvModal.hide("modal-details-remark");
    },
    openModel(remark) {
      this.remarks = remark;
      this.$bvModal.show("remarks-modal");
    },
    hideModal() {
      console.log('remarks clicked');
      this.$bvModal.hide("remarks-modal");
    },
    onBtnExport() {
      console.log('client export')
      this.show = true // Add this line to show overlay
      store
        .dispatch('app-client/fetchClientsExport')
        .then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);
          // create "a" HTLM element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `Clients Export List`);
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          this.show = false // Add this line to hide overlay
        })
        .catch((error) => {
          this.show = false // Add this line to hide overlay on error
          console.error('Export failed:', error)
        });
    },
    onBtnExportSimple() {
      console.log('client export')
      this.show = true // Add this line to show overlay
      store
        .dispatch('app-client/fetchClientsExportSimple')
        .then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);
          // create "a" HTLM element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `Clients Export List`);
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          this.show = false // Add this line to hide overlay
        })
        .catch((error) => {
          this.show = false // Add this line to hide overlay on error
          console.error('Export failed:', error)
        });
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-client";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, clientStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientListTable,
      refetchData,
      ability,
      statusFilter,
    } = useClientsList();

    return {
      // Sidebar
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientListTable,
      refetchData,
      ability,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.cursor {
  cursor: pointer;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
